<template>
   <div class="login-form">
    <h1>ADMIN PAGE</h1>
    <h2>Welcome Back!</h2>
    <div class="loginWrap">
       <div class="input-box dp-flex">
         <input type="text" placeholder="ID" id="adminId" ref="siteId" v-model="model.siteId" @keyup.enter="onSubmit" />
       </div>
       <div class="input-box dp-flex">
         <input type="password" placeholder="Password" id="adminPw" ref="sitePass" v-model="model.sitePass" @keyup.enter="onSubmit" />
       </div>
       <div class="input-box dp-flex captcha-box">
         <VueClientRecaptcha
            :width="120"
            :value="inputValue"
            :count="4"
            chars="1234"
            :hideLines="true"
            custom-text-color="black"
            @getCode="getCaptchaCode"
            @isValid="checkValidCaptcha"
         >
           <template>
            <span style="color: blue">with Custom Text Or Icon</span>
           </template>
         </VueClientRecaptcha>
         <input
            v-model="inputValue"
            placeholder="자동입력방지코드"
            class="input"
            type="text"
            @keyup.enter="onSubmit"
         />
       </div>
       <button class="signIn" type="button" @click="onSubmit">LOGIN</button>
    </div>
    <p class="loginFooter">use for <span>OMS</span> version 2.0.2</p>
  </div>

  <div class="modal-wrapper" v-if="modalActive">
    <div class="modal-wrap">
      <div class="sub-title">
        <h3>OTP Verification</h3>
        <a href='#' @click="modalActive = false"><img :src="require('@/assets/img/icon_closeR.svg')" /></a>
      </div>
      <div class="page-content">
        <div class="input-box dp-flex">
          <input type="text" ref="otp" v-model="otp" @keyup.enter="optSubmit" />
        </div>
        <div class="status-change-btn-wrap pt50">
          <button class="signIn" type="button" @click="optSubmit()">확인하기</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSiteData, setAuthData, getAuthToken } from '@/libs/auth-helper'
import { signIn } from '@/api/member'
import store from '@/store'

import VueClientRecaptcha from 'vue-client-recaptcha'

export default {
  name: 'Login',
  components: {
    VueClientRecaptcha
  },
  data () {
    return {
      model: {
        siteId: '',
        sitePass: ''
      },
      otp: '',
      modalActive: false,
      data: {
        captchaCode: null,
        isValid: false
      },
      inputValue: null
    }
  },
  computed: {
    canSubmit () {
      return this.model.siteId && this.model.sitePass
    }
  },
  created () {

  },
  methods: {
    /*inputc (ck) {
      document.querySelector('#' + ck).scrollIntoView({ behavior: 'smooth' })
   },*/
    setReloadCaptcha () {
      setTimeout(() => {
        if (document.querySelector('.vue_client_recaptcha_icon')) {
          document.querySelector('.vue_client_recaptcha_icon').click()
        }
      }, 500)
    },
    getCaptchaCode (value) {
      this.data.captchaCode = value
    },
    checkValidCaptcha (value) {
      this.data.isValid = value
    },
    goPage () {
      this.$router.replace({ path: '/dash' })
    },
    modalOpen () {
      this.modalActive = true
      this.$nextTick(() => {
        if (this.$refs.otp) {
          this.$refs.otp.focus()
        }
      })
    },
    modalClose () {
      this.modalActive = false
      this.model.siteId = ''
      this.model.sitePass = ''
      this.otp = ''
    },
    async optSubmit () {
      if (!this.otp) {
        alert('otp를 입력해주세요.')
        this.$nextTick(() => {
          this.$refs.otp.focus()
        })
      }
      const params = { ...this.model, otp: this.otp }
      console.log(params)
      const res = await signIn(params)
      const resData = res.data
      const succ = res.resultCode

      if (succ === '0') {
        setAuthData(resData)
        await store.dispatch('storeMenuDate')
        this.goPage()
      } else {
        console.log('Login Fail')
        console.log(res.resultMessage)
        alert('인증 실패, 다시 시도해주세요.')
      }
    },
    async onSubmit () {
      if (!this.data.isValid) {
        alert('자동 입력 방지 코드를 확인해주세요.')
        setTimeout(() => {
          if (document.querySelector('.vue_client_recaptcha_icon')) {
            document.querySelector('.vue_client_recaptcha_icon').click()
          }
        }, 500)

        return false
      }

      if (!this.canSubmit) {
        for (const child in this.model) {
          if (!this.model[child]) {
            alert(this.$t(`alert.login.${child}`))
            this.$refs[child].focus()
            break
          }
        }
        return
      }

      try {
        console.log('[Req]Login :', this.model)
        const res = await signIn(this.model)
        console.log('[Res]Login :', res)
        // return;
        const resData = res.data
        const succ = res.resultCode
        console.log(succ)
        if (succ === '0') {
          const otp = resData.useOtp
          if (otp === 'N') {
            setAuthData(resData)
            await store.dispatch('storeMenuDate')
            this.goPage()
          } else if (otp === 'Y') {
            this.modalOpen()
          } else {
            return
          }
        } else if (succ === '5000') {
          this.setReloadCaptcha()
          this.otp = ''
          this.modalOpen()
        } else {
          this.setReloadCaptcha()
          console.log('Login Fail')
          console.log(res.resultMessage)
          alert('로그인 실패, 다시 시도해주세요.')
        }
      } catch (err) {
        this.setReloadCaptcha()
        console.log(err)
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    const token = getAuthToken()
    const siteInfo = getSiteData()
    if (token && siteInfo) {
      next('/member')
    } else {
      next()
    }
  }
}
</script>

<style scoped>
.login-form {
   position: fixed;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: url(../assets/img/loginBg.svg) #000000d9;
    background-size: cover;
}
.login-form h1 {text-align: center;font-size: 47px;color: #fff;font-family: 'GillSansNova';}
.login-form h2 {text-align: center;font-size: 25px; color: #fff; margin-bottom: 75px;}
.loginWrap {
  width: 504px;
  height: 392px;
  box-sizing: border-box;
  padding: 70px 34px 28px;
  background-image: url(../assets/img/loginBox.svg);
  background-size: contain;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.input-box {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.input-box img {
  width: 18px;
  margin-right: 20px;
}

.input-box input {
  border-radius: 5px;
  width: 100%;
  height: 54px;
  background: #fff;
  font-size: 18px;
  color: #a4a4a4;
  border: 0 !important;
  padding-left: 15px;
}
.input-box input[type="password"] {
  letter-spacing: 4px;
}
.input-box input::placeholder {
  letter-spacing: 0px;
  color: #bfbfbf;
}
.inputchk-box {display: flex;align-items: center;gap: 7px;color: #fff;}
.inputchk-box input {border: 1px solid #545454;width: 19px;height: 19px;}
.signIn {
  width: 100%;
  height: 54px;
  font-size: 22px;
  font-weight: bold;
  border-radius: 5px;
  background: #d65b1c;
  border: 1px solid #d65b1c;
  color: #fff;
  box-sizing: border-box;
  cursor: pointer;
}
.signIn:hover {
  background: #fff;
  color: #d65b1c;
}
.loginFooter {
  color: #8a8a8c;
  margin-top: 10px;
  font-size: 16px;
  text-align: center;
}
.loginFooter span {
  color: #d65b1c;
}
.modal-wrapper {
  display: flex;
  font-size: 16px;
  align-items: center;
}
.signIn.close {
  background: red;
  border: 2px solid red;
}
.signIn.close:hover {
  background: #fff;
  color: red;
}
.sub-title {
  font-size: 14px;
  height: 42px;
  position: relative;
  margin-bottom: 30px;
  border-bottom: 3px solid #e1534e;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sub-title a {position: absolute;right: 15px;}
.sub-title a img {vertical-align: middle;}
.sub-title > h3 {
  color: #fff;
  padding: 0;
  position: relative;
}
.modal-wrap {
  border: 3px solid #d65b1c;
  min-width: unset;
  width: 24%;
  min-width: 550px;
  background: #353535;
  padding: 0;
  margin: 0;
}
.modal-wrap .input-box {
  width: 400px;
  gap: 25px;
}
.modal-wrap .input-box input {height: 63px;padding: 0;text-align: center;}
.page-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 0 27px;
}
.page-contents-wrap {
  background: none;
  padding: 0;
}
.modal-wrap .signIn {
  margin-top: 0;
  width: 100%;
  background: #d65b1c;
  width: 168px;
  height: 35px;
  font-size: 18px;
  font-weight: normal;
}
.modal-wrap .signIn:hover {
  background: #fff;
  color: #d65b1c;
}
.status-change-btn-wrap {
  width: 100%;
  gap: 5px;
  margin-top: 27px;
}

.captcha-box {

}
.captcha-box .vue_client_recaptcha {
  background-color: #fff;
  width: 52%;
  height: 50px;
  display: inline-block;
  border-radius: 5px;

  box-shadow: 0 0 6px 0 rgb(0 0 0 / 16%);
}

/deep/ .captcha-box .vue_client_recaptcha canvas {
  font-size: 14px;
}

/deep/ .captcha-box .vue_client_recaptcha .vue_client_recaptcha_icon {
  float: right;
  display: inline-block;
}

.captcha-box .input {
  position: relative;
  float: right;
  width: calc(48% - 15px);
  height: 50px;
  text-align: center;
  font-size:18px;
  letter-spacing: -0.025em;
  box-shadow: 0 0 6px 0 rgb(0 0 0 / 16%);
  border: solid 1px #222;
  background-color: #fff;
  color: #000;
  vertical-align: top;
  margin-left: 15px;
  padding: 0;
}

@media (max-width: 500px) {
  .login-form {display: block;padding-top: 50px;background: url(../assets/img/mloginBg.svg) center bottom no-repeat;background-size: cover;}
  .loginWrap{
    width: 350px;
    height: auto;
    background: none;
    padding: 0;
    margin: 0 auto;
  }
  .login-form h1 {font-size: 40px;margin-bottom: 20px;}
  .login-form h2 {display: none;}
  .input-box input{
    height: 43px;
  }
  .signIn {height: 43px;}
  .captcha-box .vue_client_recaptcha{display: flex;width: 60%;height: 42px;}
  .captcha-box .input {
    width: calc(48% - 0px);
    height: 42px;
    margin-left: 10px;
  }
  .modal-wrapper {display: block;}
  .modal-wrap {min-width: 320px;position: fixed;left: 50%;transform: translateX(-50%);top: 65px;}
  .modal-wrap .input-box {width: 100%;}
  .modal-wrap .input-box input {height: 43px;}
}
</style>
<style scoped src="@/assets/fonts/GillSansNova.css"></style>
